import { ButtonTypes } from "../../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { Panel } from "../../../../../../components/ui/Panel";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  ReviewModalTitle
} from "../../../../../../components/ui/ReviewModal";
import { StakeholderType } from "../../../../../../enums";
import { UnassignedStakeholdersGrid } from "../../../../../../pages/insight/projects/Stakeholders/UnassignedStakeholdersGrid/UnassignedStakeholdersGrid_view";
import I18n from "../../../../../localization/I18n";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import { IToasterService } from "../../../../../toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../toaster/Toaster_model";
import { GridSideModalTitle } from "../../utils/GridModalUtils";
import { WrapperActionGridWithDataLoader } from "../ActionsGrid/WrapperActionsGridWithDataLoader_view";
import { WrapperImpactGridWithDataLoader } from "../ImpactsGrid/WrapperImpactsGridWithDataLoader_view";
import { ProjectStakeholderBulkUpdateSidebarModel } from "./modals/projectStakeholderBulkUpdateSidebar/ProjectStakeholderBulkUpdateSidebar_model";
import { BulkUpdateProjectStakeholderSidebar } from "./modals/projectStakeholderBulkUpdateSidebar/ProjectStakeholderBulkUpdateSidebar_view";

export const SHOW_PROJECT_STAKEHOLDER_REVIEW_CONFIRM_MODAL = (
  projectStakeholderIds: number[],
  reviewStakeholderRange: (projectStakeholderIds: number[], comment: string) => Promise<any>,
  toastService: IToasterService,
  onCloseFn: () => void
) => {
  return new Promise(resolve => {
    let comment = "";
    const handleInputChange = ss => {
      comment = ss;
    };
    ModalContext.showConfirmDialog(
      <ReviewModalTitle />,
      <ReviewModalContent reviewCommentInput={comment} handler={handleInputChange} />,
      I18n.t("phrases.confirmReview"),
      I18n.t("phrases.cancelReview"),
      ReviewModalComponentProps,
      async () => {
        await reviewStakeholderRange(projectStakeholderIds, comment);
        ModalContext.hide();
        toastService
          .showSuccessToast()
          .setContent(<span>{I18n.t("phrases.reviewCompleted")}</span>)
          .startTimer(TOASTER_TOAST_TIME.NORMAL);
        onCloseFn();
        resolve(true);
      },
      () => {
        ModalContext.hide();
      },
      ButtonTypes.PRIMARY,
      ButtonTypes.OUTLINE_PRIMARY
    );
  });
};

export const SHOW_PROJECT_STAKEHOLDER_DELETE_CONFIRM_MODAL = (
  projectStakeholderIds: number[],
  removeItems: (itemIds: number[]) => Promise<any>
) => {
  return new Promise(resolve => {
    ModalContext.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.removeStakeholderFromProject")}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {I18n.t("phrases.confirmStakeholderRangeRemove", { name: projectStakeholderIds.length })}
          </div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        ModalContext.hide();
        await removeItems(projectStakeholderIds);
        resolve(true);
      },
      () => {
        ModalContext.hide();
      },
      ButtonTypes.DANGER
    );
  });
};

// --------------------------
export const STAKEHOLDER_LINK_MODAL_CONFIG = (projectId: number, stakeholderType: StakeholderType) => {
  return {
    showClose: false,
    title: null,
    content: (
      <UnassignedStakeholdersGrid
        stakeholderType={stakeholderType}
        projectId={projectId}
        onAssignSuccess={ModalContext.hide}
      />
    )
  };
};

export const PROJECT_STAKEHOLDER_BULK_EDIT_MODAL_CONFIG = (
  projectId: number,
  organisationId: number,
  selectedItems: number[]
) => {
  return {
    showClose: true,
    title: (
      <GridSideModalTitle
        name={I18n.t("phrases.updatingInBulk")}
        field={`${I18n.t("phrases.noOfStakeholdersSelected", {
          plural: selectedItems.length > 1 ? "s" : "",
          num: selectedItems.length
        })}`}
      />
    ),
    content: (
      <>
        <BulkUpdateProjectStakeholderSidebar
          model={
            new ProjectStakeholderBulkUpdateSidebarModel(ModalContext.hide, projectId, organisationId, selectedItems)
          }
        ></BulkUpdateProjectStakeholderSidebar>
      </>
    )
  };
};

export const PROJECT_STAKEHOLDER_LINK_TO_ACTIONS_MODAL_CONFIG = (
  selectedLinkItems: number[],
  onAssignSuccess: (itemIds: number[]) => Promise<any>
) => {
  return {
    showClose: false,
    className: "d-flex flex-column",
    panelProps: {
      className: "d-flex flex-column h-100"
    },
    title: null,
    content: <WrapperActionGridWithDataLoader onAssignSuccess={onAssignSuccess} selectedLinkItems={selectedLinkItems} />
  };
};

export const PROJECT_STAKEHOLDER_LINK_TO_IMPACT_LINK_MODAL = (
  selectedLinkItems: number[],
  onAssignSuccess: (itemIds: number[]) => Promise<any>
) => {
  return {
    showClose: false,
    className: "d-flex flex-column",
    panelProps: {
      className: "d-flex flex-column h-100"
    },
    title: null,
    content: <WrapperImpactGridWithDataLoader onAssignSuccess={onAssignSuccess} selectedLinkItems={selectedLinkItems} />
  };
};
