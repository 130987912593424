import { observer } from "mobx-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { GridHubStoreWrapper } from "../../../../../apps/insight/stores/GridHubStoreWrapper";
import { useStores } from "../../../../../apps/insight/stores/RootStore";
import { useFlightPathUser } from "../../../../../core/auth_insight";
import { ImpactsGrid } from "../../../../../core/grids/builder/directorBuilder/grids/ImpactsGrid/ImpactsGridView_view";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";

export interface DetailedImpactsViewProps {}

const DetailedImpactsView: React.FunctionComponent<DetailedImpactsViewProps> = observer(() => {
  const { projectId } = useParams<{ projectId: string }>();
  const organisationId = useCurrentOrganisationId();
  const authUser = useFlightPathUser();
  const { impactStore } = useStores();

  useEffect(() => {
    impactStore.canInvoke() && impactStore.invokeDataLoad(organisationId, +projectId, authUser);
  }, [impactStore, organisationId, projectId, authUser]);

  return (
    <GridHubStoreWrapper store={impactStore} projectId={+projectId} organisationId={organisationId} authUser={authUser}>
      <div className="detailed-impacts-view insight-tab-content__space-filler">
        <ImpactsGrid
          isLoading={impactStore.isLoading}
          data={impactStore.data}
          backendColumnDefs={impactStore.columnDefs}
          connectedUsers={impactStore.connectedUsers}
          onCellStateChange={impactStore.updateUserSelectedCell}
        />
      </div>
    </GridHubStoreWrapper>
  );
});

export { DetailedImpactsView };
