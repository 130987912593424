import { observer } from "mobx-react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { ImpactReportingViewComponent } from "./ImpactReportingView_component";
import { ImpactReportingViewModel } from "./ImpactReportingView_model";

export interface ImpactReportingViewProps {
  model?: ImpactReportingViewModel;
}

export const ImpactReportingView: React.FC<ImpactReportingViewProps> = observer(({ model: m }) => {
  const organisationId = useCurrentOrganisationId();
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string; page: string }>();
  return <ImpactReportingViewComponent organisationId={organisationId} projectId={+projectId} navigate={navigate} />;
});
