import { ICellRendererParams, ValueGetterFunc, ValueSetterParams } from "ag-grid-community";
import moment from "moment";
import { Enums } from "../../../../../../../enums";
import Datepicker from "../../../../../editors/Datepicker";
import { dateComparator } from "../../../../../utils";
import { BaseColumnBuilder } from "../../baseColumn/BaseColumn_builder";
import { ISdColDef } from "../../baseColumn/BaseColumnTypes";
import { DATE_COLUMN_CONFIG } from "./DateColumn_config";

interface IDateColumnBuilder {}

export class DateColumnBuilder extends BaseColumnBuilder implements IDateColumnBuilder {
  constructor(columnOptions?: ISdColDef<any, any>) {
    super({ ...DATE_COLUMN_CONFIG(), ...columnOptions });
  }

  withCellEditor = (fieldName: string, context: any) => {
    this.setColumnOptions({
      cellEditorSelector: () => ({ component: Datepicker, popup: true, popupPosition: "over" }),
      cellEditorPopup: true,
      cellEditorParams: { field: fieldName, defaultDate: context }
    });
    return this;
  };

  withComparator = () => {
    this.setColumnOptions({
      comparator: dateComparator
    });
    return this;
  };

  setValueFormatter = (fieldName: string) => {
    this.setColumnOptions({
      valueFormatter: columnProps =>
        columnProps.data[fieldName] ? moment(columnProps.data[fieldName]).format("L") : ""
    });
    return this;
  };

  setValueGetter = (callback: string | ValueGetterFunc<any, any>) => {
    this.setColumnOptions({
      valueGetter: callback
    });
    return this;
  };

  setValueGetterByFieldFn = fieldName => {
    return this.setValueGetter(e => moment(e.data[fieldName]));
  };

  setValueSetter = (callback: (params: ValueSetterParams) => any) => {
    this.setColumnOptions({
      valueSetter: callback
    });
    return this;
  };

  withCellRenderer = (callback: (params: ICellRendererParams) => any) => {
    this.setColumnOptions({ cellRenderer: callback });

    return this;
  };

  withCellClass = (classList: string[]) => {
    this.setColumnOptions({
      cellClass: classList
    });
    return this;
  };

  withEndDateCellRenderer = () => {
    this.setColumnOptions({
      cellRenderer: (params: ICellRendererParams) => {
        const obj = params.data;
        if (!params.data.nextReviewDue) return null;

        let now = new Date();

        let cls =
          moment(now) > moment(obj.nextReviewDue) && obj.progressStatus !== Enums.ProgressStatus.COMPLETED
            ? "text-danger"
            : "";
        cls =
          moment(now.getDate() - 7) > moment(obj.nextReviewDue) && obj.progressStatus !== Enums.ProgressStatus.COMPLETED
            ? "text-warning"
            : cls;

        let displayValue =
          obj.progressStatus !== Enums.ProgressStatus.COMPLETED ? moment(obj.nextReviewDue).format("L") : "";

        return <span className={cls}>{displayValue}</span>;
      }
    });
    return this;
  };
}
