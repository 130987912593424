import { useParams } from "react-router-dom";
import { useStores } from "../../../../../../apps/insight/stores/RootStore";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../auth_insight";
import { useEffect } from "react";
import { observer } from "mobx-react";
import { ProjectStakeholdersGrid } from "./ProjectStakeholdersGridView_view";
import { AppGridToolbarType } from "../../types/AppGrid_types";
import { IF } from "../../../../../../components/hoc/If";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { GridHubStoreWrapper } from "../../../../../../apps/insight/stores/GridHubStoreWrapper";

export const WrapperProjectStakeholderGridWithDataLoader: React.FC<{
  selectedLinkItems?: any[];
  onAssignSuccess?: any;
  gridToolbarType?: AppGridToolbarType;
}> = observer(({ onAssignSuccess, selectedLinkItems, gridToolbarType = "link-modal" }) => {
  const { projectStakeholderStore } = useStores();
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  const authUser = useFlightPathUser();

  useEffect(() => {
    projectStakeholderStore.canInvoke() && projectStakeholderStore.invokeDataLoad(organisationId, +projectId, authUser);
  }, [projectStakeholderStore, organisationId, projectId, authUser]);

  return (
    <GridHubStoreWrapper
      store={projectStakeholderStore}
      projectId={+projectId}
      organisationId={organisationId}
      authUser={authUser}
    >
      <div className="stakeholders-grid--view insight-tab-content__space-filler">
        <IF condition={projectStakeholderStore.isLoading}>
          <PositionedSpinner />
        </IF>

        <IF condition={!projectStakeholderStore.isLoading}>
          <ProjectStakeholdersGrid
            gridToolbarType={gridToolbarType}
            onAssignSuccess={onAssignSuccess}
            selectedLinkItems={selectedLinkItems}
            data={projectStakeholderStore.data}
            connectedUsers={projectStakeholderStore.connectedUsers}
            onCellStateChange={projectStakeholderStore.updateUserSelectedCell}
            backendColumnDefs={projectStakeholderStore.columnDefs}
          />
        </IF>
      </div>
    </GridHubStoreWrapper>
  );
});
