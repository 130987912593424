import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { GridHubStoreWrapper } from "../../../../../../apps/insight/stores/GridHubStoreWrapper";
import { useStores } from "../../../../../../apps/insight/stores/RootStore";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../auth_insight";
import { ActionsGrid } from "./ActionsGridView_view";

export const WrapperActionGridWithDataLoader: React.FC<{ selectedLinkItems: any[]; onAssignSuccess: any }> = observer(
  ({ onAssignSuccess, selectedLinkItems }) => {
    const { actionStore } = useStores();
    const organisationId = useCurrentOrganisationId();
    const { projectId } = useParams();
    const authUser = useFlightPathUser();

    return (
      <GridHubStoreWrapper
        store={actionStore}
        projectId={+projectId}
        organisationId={organisationId}
        authUser={authUser}
      >
        <div className="insight-tab-content__space-filler">
          <ActionsGrid
            gridToolbarType="link-modal"
            onAssignSuccess={onAssignSuccess}
            selectedLinkItems={selectedLinkItems}
            data={actionStore.data}
            isLoading={actionStore.isLoading}
          />
        </div>
      </GridHubStoreWrapper>
    );
  }
);
