import { action, makeObservable, observable } from "mobx";
import { Component } from "react";
import { NavigateFunction } from "react-router-dom";
import { Panel } from "../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import {
  CommonColDefFieldNamesEnum,
  ImpactColDefFieldNamesEnum
} from "../../../../core/grids/builder/enums/AgGridColDefFieldNameEnum";
import I18n from "../../../../core/localization/I18n";
import Pages from "../../../../routes/InsightRoutes";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../services/api/v2/projects/Projects.api";
import ImpactReportsHub, {
  ImpactReportsHub as IImpactReportsHub
} from "../../../../services/hubs/ImpactReportsHub/impactreports_hub";
import ImpactsHub, { ImpactsHub as IImpactsHub } from "../../../../services/hubs/ImpactsHub/Impacts_hub";
import { ChangeImpactAssessment } from "./ChangeImpactAssessment/ChangeImpactAssessment_view";
import { ImpactReportingViewChartProps } from "./ImpactReportingView_ChartProps";
import { TopImpactsReportingBar } from "./TopImpactsReportingBar_view";

interface ImpactReportingViewModelProps {
  organisationId: number;
  projectId: number;
  navigate: NavigateFunction;
}
export class ImpactReportingViewComponent extends Component<ImpactReportingViewModelProps, { isLoading: boolean }> {
  projectProvider: IProjectsApi;
  @observable impactCount: number;
  @observable impactGroupCount: number;
  @observable impacts: FP.Entities.IImpactSummary[];
  impactReportsHub: IImpactReportsHub;
  impactsHub: IImpactsHub;

  @observable byTypeChartDataProps: ImpactReportingViewChartProps;
  @observable byLocationChartDataProps: ImpactReportingViewChartProps;
  @observable byBusinessAreaChartDataProps: ImpactReportingViewChartProps;
  @observable byOwnerAreaChartDataProps: ImpactReportingViewChartProps;
  @observable byStakeholderSentimentChartDataProps: ImpactReportingViewChartProps;
  @observable byStakeholderCommitmentChartDataProps: ImpactReportingViewChartProps;
  @observable byStakeholderReceptivenessChartDataProps: ImpactReportingViewChartProps;
  navigate: NavigateFunction;

  @observable ragData: any[];
  averageDataConfidence: number = 0;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.state = { isLoading: true };
    this.projectProvider = ProjectsApi;
    this.impactReportsHub = ImpactReportsHub;
    this.impactsHub = ImpactsHub;

    this.setCountsForImpactAndImpactGroup();

    this.byTypeChartDataProps = new ImpactReportingViewChartProps(
      this.props.organisationId,
      this.props.projectId,
      this.props.navigate
    );
    this.byLocationChartDataProps = new ImpactReportingViewChartProps(
      this.props.organisationId,
      this.props.projectId,
      this.props.navigate
    );
    this.byBusinessAreaChartDataProps = new ImpactReportingViewChartProps(
      this.props.organisationId,
      this.props.projectId,
      this.props.navigate
    );
    this.byOwnerAreaChartDataProps = new ImpactReportingViewChartProps(
      this.props.organisationId,
      this.props.projectId,
      this.props.navigate
    );
    this.byStakeholderSentimentChartDataProps = new ImpactReportingViewChartProps(
      this.props.organisationId,
      this.props.projectId,
      this.props.navigate
    );
    this.byStakeholderCommitmentChartDataProps = new ImpactReportingViewChartProps(
      this.props.organisationId,
      this.props.projectId,
      this.props.navigate
    );
    this.byStakeholderReceptivenessChartDataProps = new ImpactReportingViewChartProps(
      this.props.organisationId,
      this.props.projectId,
      this.props.navigate
    );
  }

  async componentDidMount() {
    await this.registerSocketEvents();
    this.setState({ isLoading: false });
  }

  async componentWillUnmount() {
    await this.impactReportsHub.stopConnection();
    await this.impactsHub.stopConnection();
  }

  registerSocketEvents = async () => {
    if (this.impactReportsHub.isConnectionStarted === true) {
      await this.impactReportsHub.stopConnection();
    }
    await this.impactReportsHub.startConnection();

    if (this.impactsHub.isConnectionStarted === true) {
      await this.impactsHub.stopConnection();
    }
    await this.impactsHub.startConnection();

    this.impactReportsHub.onImpactsByTypeChartData(d => {
      this.setByTypeData(d);
    });

    this.impactReportsHub.onImpactsByLocationChartData(d => {
      this.setByLocationData(d);
    });

    this.impactReportsHub.onImpactsByBusinessAreaChartData(d => {
      this.setByBusinessAreaData(d);
    });

    this.impactReportsHub.onImpactsByOwnerChartData(d => {
      this.setByOwnerData(d);
    });

    this.impactReportsHub.onImpactsByStakeholderSentimentChartData(d => {
      this.setByStakeholderSentimentData(d);
    });

    this.impactReportsHub.onImpactsByStakeholderCommitmentChartData(d => {
      this.setByStakeholderCommitmentData(d);
    });

    this.impactReportsHub.onImpactsByStakeholderReceptivenessChartData(d => {
      this.setByStakeholderReceptivenessData(d);
    });

    this.impactsHub.onLoadData(d => {
      this.setData(d.data);
      this.loadImpactLevelStatus();
    });

    await this.impactReportsHub.InvokeImpactReportByTypeData(this.props.organisationId, this.props.projectId);
    await this.impactReportsHub.InvokeImpactReportByLocationData(this.props.organisationId, this.props.projectId);
    await this.impactReportsHub.InvokeImpactReportByBusinessAreaData(this.props.organisationId, this.props.projectId);
    await this.impactReportsHub.InvokeImpactReportByOwnerData(this.props.organisationId, this.props.projectId);
    // await this.impactReportsHub.InvokeImpactReportByStakeholderSentimentData(this.props.organisationId, this.props.projectId);
    // await this.impactReportsHub.InvokeImpactReportByStakeholderCommitmentData(this.props.organisationId, this.props.projectId);
    // await this.impactReportsHub.InvokeImpactReportByStakeholderReceptivenessData(this.props.organisationId, this.props.projectId);
    await this.impactsHub.invokeLoadData(this.props.organisationId, this.props.projectId);
  };

  @action
  setByTypeData = chartData => {
    this.byTypeChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByLocationData = chartData => {
    this.byLocationChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByBusinessAreaData = chartData => {
    this.byBusinessAreaChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByOwnerData = chartData => {
    this.byOwnerAreaChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByStakeholderSentimentData = chartData => {
    this.byStakeholderSentimentChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByStakeholderCommitmentData = chartData => {
    this.byStakeholderCommitmentChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setByStakeholderReceptivenessData = chartData => {
    this.byStakeholderReceptivenessChartDataProps.setData(chartData);
    this.calculateAverageDataConfidence();
  };

  @action
  setData = impacts => {
    this.impacts = impacts;
    this.impactCount = impacts.length;
  };

  @action
  setCountsForImpactAndImpactGroup = async () => {
    let res = await this.projectProvider.getImpactsAndImpactGroupsCount(
      this.props.organisationId,
      this.props.projectId
    );
    this.impactGroupCount = res.payload.impactGroupCount;
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      this.props.navigate(
        Pages.projects.impacts.visualisations.impactAssesment.generateLink(
          this.props.organisationId,
          this.props.projectId
        )
      );
      return;
    }

    if (newTabIndex === 0) {
      this.props.navigate(
        Pages.projects.impactGroups.listView.generateLink(this.props.organisationId, this.props.projectId)
      );
      return;
    }
    this.props.navigate(Pages.projects.impacts.listView.generateLink(this.props.organisationId, this.props.projectId));
  };

  @action
  calculateAverageDataConfidence = () => {
    let allDataConfidence: number[] = [
      this.byTypeChartDataProps.DataConfidence,
      this.byLocationChartDataProps.DataConfidence,
      this.byBusinessAreaChartDataProps.DataConfidence,
      this.byOwnerAreaChartDataProps.DataConfidence
      // this.byStakeholderSentimentChartDataProps.DataConfidence,
      // this.byStakeholderCommitmentChartDataProps.DataConfidence,
      // this.byStakeholderReceptivenessChartDataProps.DataConfidence
    ];

    this.averageDataConfidence = Math.round(
      allDataConfidence.reduce((partialSum, a) => partialSum + a, 0) / allDataConfidence.length
    );
  };

  @action
  loadImpactLevelStatus = () => {
    this.ragData = [
      {
        label: I18n.t("visualisations.level_high"),
        value: this.impacts.filter(e => e.impactLevel > 7).length,
        id: "high"
      },
      {
        label: I18n.t("visualisations.level_medium"),
        value: this.impacts.filter(e => e.impactLevel > 4 && e.impactLevel < 8).length,
        id: "medium"
      },
      {
        label: I18n.t("visualisations.level_low"),
        value: this.impacts.filter(e => e.impactLevel > -1 && e.impactLevel < 5).length,
        id: "low"
      },
      {
        label: I18n.t("visualisations.Unknown"),
        value: this.impacts.filter(e => e.impactLevel === -1 || e.impactLevel === 0).length,
        id: "unknown"
      }
    ];
  };

  render = () => {
    if (this.state.isLoading) {
      return <PositionedSpinner />;
    }

    return (
      <div>
        <div className="container-fluid">
          <TopImpactsReportingBar
            ragData={this.ragData}
            impactCount={this.impactCount}
            dataConfidence={this.averageDataConfidence}
            organisationId={this.props.organisationId}
            projectId={this.props.projectId}
          />
          <div className="row">
            <div className="col-md-6">
              <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
                {!this.byTypeChartDataProps.IsLoading && (
                  <ChangeImpactAssessment
                    impacts={this.byTypeChartDataProps.Data}
                    dataConfidence={this.byTypeChartDataProps.DataConfidence}
                    heading={I18n.t("visualisations.impactsByType")}
                    gapAnalysisClick={this.byTypeChartDataProps.gapAnalysisOnClickFunction(
                      ImpactColDefFieldNamesEnum.ImpactTypes
                    )}
                    drillThroughClick={this.byTypeChartDataProps.drillThroughOnClickFunction(
                      ImpactColDefFieldNamesEnum.ImpactTypes
                    )}
                    noDataLabel={noDataLabel(I18n.t("visualisations.impactsByType"))}
                  />
                )}
              </Panel.Panel>
            </div>
            <div className="col-md-6">
              <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
                {!this.byLocationChartDataProps.IsLoading && (
                  <ChangeImpactAssessment
                    impacts={this.byLocationChartDataProps.Data}
                    dataConfidence={this.byLocationChartDataProps.DataConfidence}
                    heading={I18n.t("visualisations.impactsByLocation")}
                    gapAnalysisClick={this.byLocationChartDataProps.gapAnalysisOnClickFunction(
                      ImpactColDefFieldNamesEnum.Locations
                    )}
                    drillThroughClick={this.byLocationChartDataProps.drillThroughOnClickFunction(
                      ImpactColDefFieldNamesEnum.Locations
                    )}
                    noDataLabel={noDataLabel(I18n.t("visualisations.impactsByLocation"))}
                  />
                )}
              </Panel.Panel>
            </div>
          </div>
          <div className="row mb-4">
            <div className={"col-md-6"}>
              <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
                {!this.byBusinessAreaChartDataProps.IsLoading && (
                  <ChangeImpactAssessment
                    impacts={this.byBusinessAreaChartDataProps.Data}
                    dataConfidence={this.byBusinessAreaChartDataProps.DataConfidence}
                    heading={I18n.t("visualisations.impactsByBusinessArea")}
                    gapAnalysisClick={this.byBusinessAreaChartDataProps.gapAnalysisOnClickFunction(
                      ImpactColDefFieldNamesEnum.BusinessAreas
                    )}
                    drillThroughClick={this.byBusinessAreaChartDataProps.drillThroughOnClickFunction(
                      ImpactColDefFieldNamesEnum.BusinessAreas
                    )}
                    noDataLabel={noDataLabel(I18n.t("visualisations.impactsByBusinessArea"))}
                  />
                )}
              </Panel.Panel>
            </div>
            <div className="col-md-6">
              <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
                {!this.byOwnerAreaChartDataProps.IsLoading && (
                  <ChangeImpactAssessment
                    impacts={this.byOwnerAreaChartDataProps.Data}
                    dataConfidence={this.byOwnerAreaChartDataProps.DataConfidence}
                    heading={I18n.t("visualisations.impactsByOwner")}
                    gapAnalysisClick={this.byOwnerAreaChartDataProps.gapAnalysisOnClickFunction(
                      CommonColDefFieldNamesEnum.Owner
                    )}
                    drillThroughClick={this.byOwnerAreaChartDataProps.drillThroughOnClickFunction(
                      CommonColDefFieldNamesEnum.Owner
                    )}
                    noDataLabel={noDataLabel(I18n.t("visualisations.impactsByOwner"))}
                  />
                )}
              </Panel.Panel>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const noDataLabel = (label: string) => {
  return `No ${label} Data`;
};
