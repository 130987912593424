import { ISdColDef } from "../../baseColumn/BaseColumnTypes";
import { BaseTextColumnBuilder } from "../../baseColumn/BaseTextColumn_builder";
import { SELECTION_COLUMN_CONFIG } from "./SelectionColumn_config";

interface ISelectionColumnBuilder {}

export class SelectionColumnBuilder extends BaseTextColumnBuilder implements ISelectionColumnBuilder {
  constructor(columnOptions?: ISdColDef<any, any>) {
    super({ ...SELECTION_COLUMN_CONFIG(), ...columnOptions });
  }
}
