import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../BaseApiModel";
import { IFilteredOptions } from "../filteredApi/FilteredApiModel";
import { ExportableProjectApi } from "../exportableApi/ExportableProjectApiModel";
import { ImpactField } from "../../../../enums";
import http from "../Http";

export class ImpactsApi extends ExportableProjectApi<FP.Entities.IImpact> {
  controller: string = "impacts";

  constructor(http: AxiosInstance) {
    super(http, "impacts");
  }

  getStakeholders = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ) => {
    let url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/project-stakeholders`;
    const res = await this.http.get(url, config);
    return res.data;
  };

  deleteRange = async (organisationId: number, projectId: number, ids: number[], config?: AxiosRequestConfig) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/delete-range`,
      ids,
      config
    );
    return res.data;
  };

  updateField = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    impactField: ImpactField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact>> => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/${impactField}`,
      data,
      config
    );
    return res.data;
  };

  reviewRange = async (
    organisationId: number,
    projectId: number,
    ids: number[],
    comment: string,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/review-range`,
      { impactIds: ids, comment },
      config
    );
    return res.data;
  };

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    projectId?: number,
    organisationId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);

    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}${query}`,
      config
    );
    return res.data;
  };

  getMixedFiltered = async (
    organisationId: number,
    projectId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => {
    const query = this.getRequestQuery(filterOptions);

    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/all-types${query}`,
      config
    );
    return res.data;
  };

  getDetailedById = async (organisationId: number, projectId: number, id: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}/detailed`,
      config
    );
    return res.data;
  };

  getUnassignedStakeholders = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/unassignedStakeholders`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getImpactsWithNoImpactGroup = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/with-no-impact-groups`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getUnassignedActions = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAction[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/unassignedActions`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getActions = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAction[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/actions`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getUnassignedStGroups = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/unassignedStGroups`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  addProjectStakeholder = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    stakeholderIds: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/stakeholders`;
    let res = await this.http.post(url, stakeholderIds, config);
    return res.data;
  };

  getStakeholderEmailsByImpactIds = async (
    organisationId: number,
    projectId: number,
    impactIds: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<string[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${
      this.controller
    }/get-stakeholder-emails?impactIds=${impactIds.join(",")}`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<string[]>;
    }
  };

  addMilestone = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    milestoneId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/milestones`;
    let res = await this.http.post(url, { milestoneId }, config);
    return res.data;
  };

  removeMilestone = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    milestoneId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/milestones/${milestoneId}`;
    let res = await this.http.delete(url, config);
    return res.data;
  };

  addStakeholderGroups = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    stGroups: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/stakeholder-groups`;
    let res = await this.http.post(url, stGroups, config);
    return res.data;
  };

  addActions = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    stGroups: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAction[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/actions`;
    let res = await this.http.post(url, stGroups, config);
    return res.data;
  };

  addBulkActionsToImpacts = async (
    organisationId: number,
    projectId: number,
    impactIds: number[],
    actionIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-bulk-actions-to-impacts`;
    let res = await this.http.post(url, { actionIds, impactIds }, config);
    return res.data;
  };

  addBulkLocations = async (
    organisationId: number,
    projectId: number,
    impactIds: number[],
    locations: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-bulk-locations-to-impacts`;
    let res = await this.http.post(url, { locations, impactIds }, config);
    return res.data;
  };

  addBulkLabels = async (
    organisationId: number,
    projectId: number,
    impactIds: number[],
    labelIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-bulk-tags-to-impacts`;
    let res = await this.http.post(url, { impactIds, tagIds: labelIds }, config);
    return res.data;
  };

  addBulkBusinessAreas = async (
    organisationId: number,
    projectId: number,
    impactIds: number[],
    businessAreas: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-bulk-business-areas-to-impacts`;
    let res = await this.http.post(url, { businessAreas, impactIds }, config);
    return res.data;
  };

  addBulkImpactGroups = async (
    organisationId: number,
    projectId: number,
    impactIds: number[],
    impactGroups: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-bulk-impact-groups-to-impacts`;
    let res = await this.http.post(url, { impactGroups, impactIds }, config);
    return res.data;
  };

  bulkUpdateImpacts = async (
    organisationId: number,
    projectId: number,
    impactIds: number[],
    fields: any,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/bulk-update-impacts`;
    let res = await this.http.post(url, { impactIds, ...fields }, config);
    return res.data;
  };

  removeAction = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    actionId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/actions/${actionId}`;
    let res = await this.http.delete(url, config);
    return res.data;
  };

  duplicateImpacts = async (
    organisationId: number,
    projectId: number,
    impactIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/duplicate-impacts`;
    let res = await this.http.post(url, { impactIds: impactIds }, config);
    return res.data;
  };

  updateBusinessAreas = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    businessAreaIds: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/business-areas`;
    let res = await this.http.put(url, { businessAreaIds }, config);
    return res.data;
  };

  getBusinessAreaSelection = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ITreeDataItem[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/business-areas`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  addBusinessArea = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    businessAreaId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IBusinessArea[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/business-areas`;
    let res = await this.http.post(url, { businessAreaId }, config);
    return res.data;
  };

  removeBusinessArea = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    businessAreaId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/business-areas/${businessAreaId}`;
    let res = await this.http.delete(url, config);
    return res.data;
  };

  addLocation = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    locationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ILocation[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/locations`;
    let res = await this.http.post(url, { locationId }, config);
    return res.data;
  };

  removeLocation = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    locationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/locations/${locationId}`;
    let res = await this.http.delete(url, config);
    return res.data;
  };

  removeStakeholder = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    stakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/stakeholders/${stakeholderId}`;
    let res = await this.http.delete(url, config);
    return res.data;
  };

  getUnselectedBusinessAreas = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IBusinessArea[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/unselected-business-areas`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getUnselectedLocations = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ILocation[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/unselected-locations`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getLocationSelection = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ITreeDataItem[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/locations`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  updateLocations = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    locationIds: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/locations`;
    let res = await this.http.put(url, { locationIds }, config);
    return res.data;
  };

  getComments = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IComment[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/comments`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  addComment = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    comment: FP.Entities.IComment,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IComment>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/comments`;
    let res = await this.http.post(url, comment, config);
    return res.data;
  };

  async deleteGridField(
    organisationId: number,
    projectId: number,
    impactId: number,
    impactField: ImpactField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/${impactField}`,
      config
    );
    return res.data;
  }

  shiftImpactDates = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    days: number,
    shiftActions: boolean,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/shiftdates`;
    let res = await this.http.put(url, { days, shiftActions }, config);
    return res.data;
  };

  getDataHistory = async (organisationId: number, projectId: number, impactId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/short-history/`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getProjectImpactCreators = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IUserPermission[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/project-impact-creators`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  addMultiple = async (
    organisationId: number,
    projectId: number,
    impacts: FP.Entities.IImpact[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-multiple`;
    let res = await this.http.post(url, { impacts }, config);
    return res.data;
  };
}

const instance = new ImpactsApi(http);
export default instance;
