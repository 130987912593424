import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { GridHubStoreWrapper } from "../../../../../../apps/insight/stores/GridHubStoreWrapper";
import { useStores } from "../../../../../../apps/insight/stores/RootStore";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../auth_insight";
import { ImpactsGrid } from "./ImpactsGridView_view";

export const WrapperImpactGridWithDataLoader: React.FC<{ selectedLinkItems: any[]; onAssignSuccess: any }> = observer(
  ({ onAssignSuccess, selectedLinkItems }) => {
    const { impactStore } = useStores();
    const organisationId = useCurrentOrganisationId();
    const { projectId } = useParams();
    const authUser = useFlightPathUser();

    return (
      <GridHubStoreWrapper
        store={impactStore}
        projectId={+projectId}
        organisationId={organisationId}
        authUser={authUser}
      >
        <ImpactsGrid
          isLoading={impactStore.isLoading}
          gridToolbarType="link-modal"
          onAssignSuccess={onAssignSuccess}
          selectedLinkItems={selectedLinkItems}
          data={impactStore.data}
          backendColumnDefs={impactStore.columnDefs}
        />
      </GridHubStoreWrapper>
    );
  }
);
