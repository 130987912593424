import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../BaseApiModel";
import http from "../Http";
import { ExportableProjectApi } from "../exportableApi/ExportableProjectApiModel";
import { ProjectProcessField } from "../../../../enums";

export class ProjectProcessesApi extends ExportableProjectApi<any> {
  controller: string = "project-processes";

  constructor(http: AxiosInstance) {
    super(http, "project-processes");
  }

  createProjectProcess = async (organisationId: number, projectId: number, data: any, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-bulk-processes`;
    let res = await this.http.post(url, { processIds: data }, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getLocationSelection = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ITreeDataItem[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/locations`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getDetailedById = async (organisationId: number, projectId: number, id: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}/detailed`,
      config
    );
    return res.data;
  };

  updateLocations = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    locationIds: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/locations`;
    let res = await this.http.put(url, { locationIds }, config);
    return res.data;
  };

  addBulkLocations = async (
    organisationId: number,
    projectId: number,
    projectProcessIds: number[],
    locations: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-bulk-locations-to-project-processes`;
    let res = await this.http.post(url, { locations, projectProcessIds }, config);
    return res.data;
  };

  updateField = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    projectProcessField: ProjectProcessField,
    config?: AxiosRequestConfig
  ) => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/${projectProcessField}`,
      data,
      config
    );
    return res.data;
  };

  getImpactGroups = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpactGroup[]>> => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/impact-groups`,
      config
    );
    return res.data;
  };

  getRolesByOrgId = async (organisationId: number, projectId: number, config?: any) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/process-roles`,
      config
    );
    return res.data;
  };

  getAppsByOrgId = async (organisationId: number, projectId: number, config?: any) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/process-apps`,
      config
    );
    return res.data;
  };

  removeImpactGroup = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    impactGroupId: number,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/impact-groups/${impactGroupId}`,
      config
    );
    return res.data;
  };

  addImpactGroup = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    impactGroupId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/impact-groups/${impactGroupId}`,
      config
    );
    return res.data;
  };

  addMultipleImpacts = async (
    organisationId: number,
    projectId: number,
    projectProcessIds: number[],
    impactIds: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-bulk-impacts-to-project-processes`,
      { impactIds, projectProcessIds },
      config
    );
    return res.data;
  };

  deleteImpact = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/impacts/${impactId}`,
      config
    );
    return res.data;
  };

  addMultipleImpactGroups = async (
    organisationId: number,
    projectId: number,
    impactGroupIds: number[],
    projectProcessIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-multiple-impact-groups`;
    let res = await this.http.post(url, { impactGroupIds, projectProcessIds }, config);
    return res.data;
  };

  getTagsById = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ITag[]>> => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/tags`,
      config
    );
    return res.data;
  };

  getProcessApps = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProcessApp[]>> => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/process-apps`,
      config
    );
    return res.data;
  };

  addProcessApp = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    processAppId: number,
    name: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/process-apps/${processAppId}`,
      { name },
      config
    );
    return res.data;
  };

  removeProcessApp = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    processAppId: number,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/process-apps/${processAppId}`,
      config
    );
    return res.data;
  };

  getProcessRoles = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProcessRole[]>> => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/process-roles`,
      config
    );
    return res.data;
  };

  addProcessRole = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    processRoleId: number,
    name: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/process-roles/${processRoleId}`,
      { name },
      config
    );
    return res.data;
  };

  removeProcessRole = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    processRoleId: number,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/process-roles/${processRoleId}`,
      config
    );
    return res.data;
  };

  deleteRange = async (organisationId: number, projectId: number, ids: number[], config?: AxiosRequestConfig) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/delete-range`,
      ids,
      config
    );
    return res.data;
  };

  removeBusinessProcess = async (
    organisationId: number,
    projectId: number,
    systemProjectProcessId: number,
    businessProjectProcessId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/remove-business-project-processes`,
      { systemProjectProcessId, businessProjectProcessId },
      config
    );
    return res.data;
  };

  getProjectSystemProcesses = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/system-processes`,
      config
    );
    return res.data;
  };

  getSystemProcesses = async (
    organisationId: number,
    projectId: number,
    processId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${processId}/system-processes`,
      config
    );
    return res.data;
  };

  assignStakeholderOwners = async (
    organisationId: number,
    projectId: number,
    projectProcessIds: number[],
    stakeholderIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/stakeholder-owners`,
      { projectProcessIds, projectStakeholderIds: stakeholderIds },
      config
    );
    return res.data;
  };

  getUnassignedStakeholderOwners = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/project-stakeholder-owners/unassigned`,
      config
    );
    return res.data;
  };

  getStakeholderOwnersByProjectProcessId = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder[]>> => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/project-stakeholder-owners`,
      config
    );
    return res.data;
  };

  removeProjectProcessStakeholderOwner = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    projectStakeholderId: number,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/project-stakeholder-owners/${projectStakeholderId}`,
      config
    );
    return res.data;
  };

  getImpactByProjectProcessId = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/impacts`,
      config
    );
    return res.data;
  };

  getUnassignedImpactsByProjectProcessId = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/unassigned-impacts`,
      config
    );
    return res.data;
  };
}

const instance = new ProjectProcessesApi(http);
export default instance;
