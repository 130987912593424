import { ColDef } from "ag-grid-community";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import Pages from "../../../../../../routes/InsightRoutes";
import ImpactGroupsApi, {
  ImpactGroupsApi as IImpactGroupsApi
} from "../../../../../../services/api/v2/impactGroups/ImpactGroups.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import { gridSideModalAnimationOpts, gridSideModalComponentProps } from "../../../../GridSideModals";
import { CommonColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { BASE_FILTER_CONFIG } from "../../columns/baseColumn/BaseColumn_config";
import { DescriptionColumnBuilder } from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_builder";
import { DESCRIPTION_COLUMN_CONFIG } from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_config";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { NAME_COLUMN_CONFIG, NAME_FILTER_CONFIG } from "../../columns/commonColumns/NameColumn/NameColumn_config";
import { NoteCountColumnBuilder } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_builder";
import { NOTE_COUNT_COLUMN_CONFIG } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_config";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { ImpactGroupField } from "../../utils/GridFields";
import { GridSideModalTitle } from "../../utils/GridModalUtils";
import { BaseGridColumnBuilder } from "../base/BaseGridColumnBuilder";
import { IMPACTS_SIDEBAR_MODAL_CONFIG } from "./modals/ImpactsSidebar/ImpactsSidebar_config";
import { ImpactGroupNotesSidebar } from "./modals/noteCountModal/ImpactGroupNotesSidebar";

export interface ImpactGroupsGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  userCanViewImpacts: boolean;
  columns: string[];
}

export class ImpactGroupsGridColumnBuilder extends BaseGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: ImpactGroupsGridColumnBuilderProps;
  impactGroupsProvider: IImpactGroupsApi = ImpactGroupsApi;
  columnDefs: Dictionary<() => ColDef>;
  organisationId: number;
  projectId: number;

  constructor(gridProps: ImpactGroupsGridColumnBuilderProps) {
    super(ImpactGroupsApi.updateField, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.projectId = gridProps.projectId;
    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Name]: () => this.buildNameColumn(),
      [CommonColDefFieldNamesEnum.Description]: () => this.buildDescriptionColumn(),
      [CommonColDefFieldNamesEnum.NoteCount]: () => this.buildNoteCountColumn(),
      [CommonColDefFieldNamesEnum.Impacts]: () =>
        this.buildPillsColumn(
          CommonColDefFieldNamesEnum.Impacts,
          "Impacts",
          IMPACTS_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions()
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    this.gridProps.columns.forEach(e => {
      res.push(this.columnDefs[e]());
    });

    return res;
  };

  buildNameColumn = () => {
    let model = new NameColumnBuilder()
      .makeSelectable()
      .setColumnOptions(NAME_COLUMN_CONFIG({ headerName: "Name" }))
      .withLinkCell(impactGroupId =>
        Pages.projects.impactGroups.extendedView.generateLink(this.organisationId, this.projectId, impactGroupId)
      )
      .setFilterOptions(NAME_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      // make cell editable
      model.createValueSetter(this.updateName);
    }

    return model.generateColumnOptions();
  };

  buildDescriptionColumn = () => {
    let model = new DescriptionColumnBuilder()
      .makeSelectable()
      .setColumnOptions(DESCRIPTION_COLUMN_CONFIG())
      .setFilterOptions(BASE_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      model.setEditableOnDoubleClick(this.updateDescription);
    }

    return model.generateColumnOptions();
  };

  buildNoteCountColumn = () => {
    let model = new NoteCountColumnBuilder().setColumnOptions(NOTE_COUNT_COLUMN_CONFIG()).makeSelectable();

    if (this.gridProps.canEdit) {
      model.setEditableOnDoubleClick(this.impactGroupNotesModal);
    }
    return model.generateColumnOptions();
  };

  updateDescription = async (entityId: number, text: string) => {
    await this.updateTextField(ImpactGroupField.DESCRIPTION, entityId, text);
  };

  updateName = async (entityId: number, text: string) => {
    await this.updateTextField(ImpactGroupField.NAME, entityId, text);
  };

  impactGroupNotesModal = (impactGroup: FP.Entities.IImpactGroup) => {
    ModalContext.show({
      showClose: true,
      title: <GridSideModalTitle name={impactGroup.name} field={I18n.t("phrases.tags")} />,
      content: (
        <ImpactGroupNotesSidebar
          projectId={this.projectId}
          impactGroupId={impactGroup.id}
          organisationId={this.organisationId}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };
}
