import { observer } from "mobx-react";
import { IUiAction } from "../../../../../uiAction/IUiAction";
import { UiActionComponent } from "../../../../../uiAction/UiAction";
import { MicroForm } from "../../../../../../pages/change/forms/microForm/MicroForm";
import { Tooltip } from "../../../../../../components/ui/Tooltip";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";
import { Panel } from "../../../../../../components/ui/Panel";

export interface IGridUiAction extends IUiAction<any> {
  children?: IGridUiAction[];
  type: "uiAction" | "microForm" | "devider";
  disabledWhenNoItemSelected?: boolean;
  contextProps?: any;
}

interface AppGridToolbarActionsProps {
  actions: IGridUiAction[];
  selectedItemCount: number;
}

export const AppGridToolbarActions: React.FC<AppGridToolbarActionsProps> = observer(
  ({ actions, selectedItemCount }) => {
    return (
      <div className="d-flex align-items-center">{actions ? buildActionTree(actions, selectedItemCount) : null}</div>
    );
  }
);

const buildActionTree = (actions: IGridUiAction[], selectedItemCount: number) => {
  let actionDom = actions.map(action => {
    if (action.disabledWhenNoItemSelected) {
      action.componentProps.isDisabled = selectedItemCount === 0 || !selectedItemCount;
    }

    if (action.children) {
      return (
        <Tooltip
          key={action.id}
          triggeredOn="click"
          position={TooltipPositions.BOTTOM_LEFT}
          shownElement={<UiActionComponent action={action} />}
        >
          <Panel.Panel
            background={Panel.PanelBackgrounds.BG_WHITE}
            hasShadow={true}
            hasBorderRadius={true}
            style={{ width: 180 }}
          >
            {buildActionTree(action.children, selectedItemCount)}
          </Panel.Panel>
        </Tooltip>
      );
    }

    if (action.type === "microForm") {
      return (
        <MicroForm
          key={action.id}
          fieldName={action.id}
          buttonPlaceholder={action.label}
          iconSymbol={(action as any).componentProps.symbol}
          formModel={action.contextProps.formModel}
          onSubmit={action.contextProps.onSubmit}
        />
      );
    } else if (action.type === "uiAction") {
      return <UiActionComponent action={action} key={action.id} />;
    } else if (action.type === "devider") {
      return <span key={action.id} className="vertical-line"></span>;
    }

    return null;
  });

  return actionDom;
};
