import { UrlOperators } from "../../../../../../core/grids/builder/directorBuilder/grids/base/addons/FilterStoreAddon";
import {
  ActionColDefFieldNamesEnum,
  ImpactColDefFieldNamesEnum
} from "../../../../../../core/grids/builder/enums/AgGridColDefFieldNameEnum";
import { ElementTypes } from "../../../../../../enums";
import Pages from "../../../../../../routes/InsightRoutes";

export const getSunburstDrillThroughUrl = (datum: any, organisationId: number): string => {
  const type = datum.data.elementType;
  const projectId = datum.data.parentId;
  switch (type) {
    case ElementTypes.STAKEHOLDER:
      return goToStakeholders(organisationId, projectId, "stakeholders");
    case ElementTypes.AUDIENCE:
      return goToStakeholders(organisationId, projectId, "audiences");
    case ElementTypes.UNKNOWN_LEVEL_IMPACT:
      return goToImpacts(organisationId, projectId, "Unknown");
    case ElementTypes.LOW_LEVEL_IMPACT:
      return goToImpacts(organisationId, projectId, "Low");
    case ElementTypes.MEDIUM_LEVEL_IMPACT:
      return goToImpacts(organisationId, projectId, "Medium");
    case ElementTypes.HIGH_LEVEL_IMPACT:
      return goToImpacts(organisationId, projectId, "High");
    case ElementTypes.UNKNOWN_RAG_ACTION:
      return goToActions(organisationId, projectId, "Unknown");
    case ElementTypes.GREEN_RAG_ACTION:
      return goToActions(organisationId, projectId, "Green");
    case ElementTypes.AMBER_RAG_ACTION:
      return goToActions(organisationId, projectId, "Amber");
    case ElementTypes.RED_RAG_ACTION:
      return goToActions(organisationId, projectId, "Red");
    case ElementTypes.COMPLETE_RAG_ACTION:
      return goToActions(organisationId, projectId, "Complete");
    default:
      break;
  }
};

const goToStakeholders = (organisationId: number, projectId: number, entity: string) => {
  const url = `${Pages.projects.index.generateLink(organisationId, projectId)}/${entity}`;
  return url;
};

const goToImpacts = (organisationId: number, projectId: number, impactLevel: string) => {
  const impactsQuery = `?${ImpactColDefFieldNamesEnum.ImpactLevel}=${UrlOperators.contains}${impactLevel}`;
  const url = `${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}${impactsQuery}`;
  return url;
};

const goToActions = (organisationId: number, projectId: number, ragStatus: string) => {
  const actionsQuery = `?${ActionColDefFieldNamesEnum.RagStatus}=${ragStatus}`;
  const url = `${Pages.projects.actions.listView.generateLink(organisationId, projectId)}${actionsQuery}`;
  return url;
};
