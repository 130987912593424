import "@ag-grid-enterprise/status-bar";
import { CellClickedEvent, ColDef, GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import "ag-grid-enterprise";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import _ from "lodash";
import { makeObservable, observable } from "mobx";
import React from "react";
import { GetCellCode } from "../../utils/GridModalUtils";
import { CustomGridToolTip } from "../../utils/GridTooltip";
import { DEFAULT_COLUMN_TYPES } from "../../utils/helpers";
import { CellSelectionAddon } from "./addons/CellSelectionAddon";
import { ColumnStateAddon } from "./addons/ColumnStateAddon";
import { FilterStoreAddon } from "./addons/FilterStoreAddon";
import { StatusBarAddon } from "./addons/StatusBarAddon";
import { TextWrapperAddon } from "./addons/TextWrapAddon";

interface AppGridProps extends Omit<AgGridReactProps, "onCellClicked"> {
  onCellEditModeChange: (cell: string, isEditing: boolean) => void;
  onCellClicked: (cell: string, isEditMode?: boolean) => void;
  filterStoreAddon?: FilterStoreAddon;
  columnStateAddon?: ColumnStateAddon;
  textWrapperAddon?: TextWrapperAddon;
  onGridStateUpdate?: any;
  connectedUsers: any[];
  shouldTrackSelectedCell: boolean;
}

export interface AppGridState {
  gridApi: GridApi<any>;
  selectedItems: number[];
  gridActions: any;
}

export class AppGrid extends React.Component<AppGridProps, AppGridState> {
  gridRef: React.RefObject<AgGridReact>;
  divRef: React.RefObject<HTMLDivElement>;
  @observable selectedItems: number[];
  statusBarAddon: StatusBarAddon;
  cellSelectionAddon: CellSelectionAddon;
  static defaultProps: Partial<AppGridProps> = {
    animateRows: true,
    rowSelection: "multiple",
    domLayout: "autoHeight",
    suppressRowClickSelection: true,
    stopEditingWhenCellsLoseFocus: true,
    cacheQuickFilter: true,
    tooltipShowDelay: 500
  };
  defaultColDef: Partial<ColDef>;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.state = {
      gridApi: null,
      selectedItems: [],
      gridActions: null
    };
    this.gridRef = React.createRef<AgGridReact>();
    this.statusBarAddon = new StatusBarAddon();
    if (props.shouldTrackSelectedCell) {
      this.cellSelectionAddon = new CellSelectionAddon();
    }
    this.defaultColDef = {
      sortable: true,
      tooltipComponent: CustomGridToolTip
    };
  }

  getState() {
    return this.state;
  }

  onGridReady = (event: GridReadyEvent) => {
    this.initialiseAddons(event.api);

    this.setState({ gridApi: event.api, selectedItems: [] }, () => {
      this.props.onGridReady && this.props.onGridReady(event);
    });
  };

  initialiseAddons = (gridApi: GridApi) => {
    this.props.filterStoreAddon?.onGridReady(gridApi);
    this.props.columnStateAddon?.onGridReady(gridApi);
    this.props.textWrapperAddon?.onGridReady(gridApi);
  };

  propagateCellClick = (event: CellClickedEvent) => {
    this.props.onCellClicked && this.props.onCellClicked(GetCellCode(event));
  };

  componentDidUpdate(prevProps: Readonly<AppGridProps>, prevState: Readonly<AppGridState>, snapshot?: any): void {
    if (prevState.gridApi !== this.state.gridApi && this.state.gridApi) {
      this.props.filterStoreAddon?.onGridReady(this.state.gridApi);
      this.props.columnStateAddon?.onGridReady(this.state.gridApi);
    }

    if (prevState.selectedItems !== this.state.selectedItems && !!this.props.onGridStateUpdate) {
      this.props.onGridStateUpdate(this.state);
    }

    if (!_.isEqual(prevProps.connectedUsers, this.props.connectedUsers) && this.props.shouldTrackSelectedCell) {
      this.cellSelectionAddon.styleSelectedCells(this.props.connectedUsers);
    }
  }

  updateSelectedItems = (event: SelectionChangedEvent) => {
    const selectedItems = event.api.getSelectedNodes().map(e => {
      return e.data.id;
    });

    this.setState({ selectedItems });
  };

  deselectRows = () => {
    if (this.state.gridApi !== undefined) this.state.gridApi.deselectAll();
  };
  setSearchText = (value: string) => {
    if (this.state.gridApi !== undefined) {
      this.state.gridApi.setGridOption("quickFilterText", value);
    }
  };

  render(): React.ReactNode {
    let filterProps = this.props.filterStoreAddon ? this.props.filterStoreAddon.getProps() : {};
    let columnStateProps = this.props.columnStateAddon ? this.props.columnStateAddon.getProps() : {};
    let statusBarProps = this.statusBarAddon.getProps();
    let agGridProps = { ...this.props };
    delete agGridProps.onCellClicked;

    return (
      <div style={{ flex: "1" }} className="ag-grid-wrapper">
        <div style={{ height: "100%", width: "100%" }}>
          <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }} ref={this.divRef}>
            <AgGridReact
              ref={this.gridRef}
              {...agGridProps}
              {...filterProps}
              {...columnStateProps}
              onSelectionChanged={this.updateSelectedItems}
              {...statusBarProps}
              columnTypes={DEFAULT_COLUMN_TYPES}
              defaultColDef={this.defaultColDef}
              onCellClicked={e => {
                this.propagateCellClick(e);
              }}
              onCellEditingStarted={event => {
                !!this.props.onCellEditModeChange && this.props.onCellEditModeChange(GetCellCode(event), true);
              }}
              onCellEditingStopped={event => {
                !!this.props.onCellEditModeChange && this.props.onCellEditModeChange(GetCellCode(event), false);
              }}
              onGridReady={(event: GridReadyEvent) => {
                this.onGridReady && this.onGridReady(event);
              }}
              domLayout={"normal"}
              suppressBrowserResizeObserver={true}
            />
          </div>
        </div>
      </div>
    );
  }
}
