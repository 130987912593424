import { AxiosInstance, AxiosRequestConfig } from "axios";
import { FilteredProjectApiModel } from "../filteredApi/FilteredProjectApiModel";
import http from "../Http";
import { AudienceStakeholderProfilingField } from "../../../../enums";

export class AudienceStakeholdersApi extends FilteredProjectApiModel<FP.Entities.IAudience> {
  controller: string = "audience-stakeholders";

  /**
   *
   */
  constructor(http: AxiosInstance) {
    super(http, "audience-stakeholders");
  }

  updateField = async (
    organisationId: number,
    projectId: number,
    id: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    field: AudienceStakeholderProfilingField,
    config?: AxiosRequestConfig
  ) => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}/${field}`,
      data,
      config
    );
    return res.data;
  };
}

const instance = new AudienceStakeholdersApi(http);
export default instance;
