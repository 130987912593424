import { ColDef, GridApi } from "ag-grid-community";
import { makeObservable, observable } from "mobx";
import { EntityTypes } from "../../../../../../../enums";
import { ILocalStorageService } from "../../../../../../../services/local/localStorageService/ILocalStorageService";
import LocalStorageService from "../../../../../../../services/local/localStorageService/LocalStorageService";
import { IGridAddon } from "./IGridAddon";
interface ITextWrapperAddonProps {}

export class TextWrapperAddon implements IGridAddon<ITextWrapperAddonProps> {
  storageService: ILocalStorageService;
  gridApi: GridApi<any>;
  @observable isWrappedText: boolean;
  wrapTextKey: string;
  projectId: number;
  gridType: EntityTypes;

  constructor(projectId: number, gridType: EntityTypes) {
    makeObservable(this);
    this.storageService = LocalStorageService;
    this.projectId = projectId;
    this.gridType = gridType;
    this.wrapTextKey = `projects-${this.projectId}-${this.gridType}-wrap-text`;
    this.isWrappedText =
      (typeof this.storageService.get(this.wrapTextKey) !== "undefined" &&
        this.storageService.get(this.wrapTextKey) === "1") ||
      this.isWrappedText;
  }

  toggleTextWrap = () => {
    this.isWrappedText = !this.isWrappedText;
    this.updateCells();
  };

  onGridReady = (gridApi: GridApi<any>) => {
    this.gridApi = gridApi;
    this.updateCells();
  };

  updateCells = () => {
    if (!this.gridApi) return;
    let cols = this.gridApi.getColumnDefs();
    if (!cols) return;
    cols.forEach((e: ColDef) => {
      if (e.autoHeight) {
        e.wrapText = this.isWrappedText;
      }
    });

    this.storageService.set(this.wrapTextKey, this.isWrappedText ? "1" : "0");
    this.gridApi.setGridOption("columnDefs", cols);
  };

  getProps = () => {
    return {};
  };
}
