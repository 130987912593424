import * as _ from "lodash";
import { observer } from "mobx-react";
import * as React from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { IInnerNavContentModel } from "./InnerNavContent_model";
import { Tab, Tabs } from "../../ui/Tabs";
import "./_inner-nav-content.scss";

export interface InnerNavContentProps {
  model: IInnerNavContentModel;
}

const InnerNavContent: React.FunctionComponent<InnerNavContentProps> = observer(({ model }: any) => {
  return (
    <div className={`inner-nav-content`}>
      {model.config && Object.keys(model.config.items).length > 0 && (
        <div className={`inner-nav-content__block inner-nav-content__block--nav ${model.blockClassName}`}>
          <h5 className="text-uppercase pl-2 mb-3">{model.config.navigationTitle}</h5>
          <ul className="inner-nav-content__list">
            {model.config &&
              model.config.items &&
              _.map(model.config.items, (e, i) => {
                return model.config.displayNavWithLinks && model.config.linkRender ? (
                  <li
                    key={e.key}
                    className={`mb-1 inner-nav-content__item ${
                      e.key === model.currentViewKey ? "inner-nav-content__item--active" : ""
                    }`}
                  >
                    {model.config.linkRender(e)}
                  </li>
                ) : (
                  <li
                    key={e.key}
                    onClick={() => model.setCurrentViewKey(e.key)}
                    className={`mb-1 inner-nav-content__item ${
                      e.key === model.currentViewKey ? "inner-nav-content__item--active" : ""
                    }`}
                  >
                    <h4 className="mb-0">{e.label}</h4>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
      <div className="inner-nav-content__block inner-nav-content__block--content">
        {model.currentViewKey && model.config.items[model.currentViewKey].content}
      </div>
    </div>
  );
});

const InsightInnerNavContent: React.FC<{ title: string; blockClassName?: string; items: any[] }> = ({
  items,
  title,
  blockClassName
}) => {
  return (
    <div className={`inner-nav-content`}>
      <div className={`inner-nav-content__block inner-nav-content__block--nav ${blockClassName}`}>
        <h5 className="text-uppercase pl-2 mb-3">{title}</h5>
        <ul className="inner-nav-content__list">
          {_.map(items, (e, i) => {
            return (
              <NavLink to={e.url} key={i}>
                {({ isActive }) => (
                  <li
                    key={i}
                    className={`mb-1 inner-nav-content__item ${isActive ? "inner-nav-content__item--active" : ""}`}
                  >
                    <h4 className="mb-0">{e.label}</h4>
                  </li>
                )}
              </NavLink>
            );
          })}
        </ul>
      </div>
      <div className="inner-nav-content__block inner-nav-content__block--content d-flex flex-column">
        <Outlet />
      </div>
    </div>
  );
};

export type InsightTabContentItem = {
  url: string;
  label: string | React.ReactNode;
};

const InsightTabContent: React.FC<{ items: InsightTabContentItem[]; currentIndex: number; className?: string }> =
  observer(({ items, currentIndex, className }) => {
    const navigate = useNavigate();
    return (
      <div className={`insight-tab-content ${className ? className : ""}`}>
        <Tabs
          key="first"
          className="tabs--primary"
          ulClassName="tabs__menu--primary"
          liClassName="tabs__item--primary"
          initialTab={currentIndex}
          onTabClicked={index => {
            navigate(items[index].url);
          }}
          currTabIdx={currentIndex}
        >
          {items.map((e, i) => {
            return <Tab key={i} title={e.label} />;
          })}
        </Tabs>
        <Outlet />
      </div>
    );
  });

export { InnerNavContent, InsightInnerNavContent, InsightTabContent };
