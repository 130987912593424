import { ISdColDef } from "../../baseColumn/BaseColumnTypes";
import { PILLS_COLUMN_CONFIG } from "./PillsColumn_config";
import { BaseColumnBuilder } from "../../baseColumn/BaseColumn_builder";
import { GridPills } from "../../../../../../../components/widgets/gridPills/GridPills";

interface IPillsColumnBuilder {}

export class PillsColumnBuilder extends BaseColumnBuilder implements IPillsColumnBuilder {
  constructor(columnOptions?: ISdColDef<any, any>) {
    super({ ...PILLS_COLUMN_CONFIG(columnOptions) });
  }

  withCellRenderer = (key: string) => {
    this.setColumnOptions({
      cellRenderer: e => <GridPills text={e.data[key]} />
    });
    return this;
  };

  setEditableOnDoubleClick = (callback: (item: any) => void) => {
    this.setColumnOptions({ onCellDoubleClicked: e => callback(e.data) });
    return this;
  };
}
