import { ColDef } from "ag-grid-community";
import { Moment } from "moment";

export const stringToDate = (input: string): Date => {
  if (typeof input !== "string") {
    let k: Moment = input;
    return new Date(k.year(), k.month(), k.date());
  }

  if (input.indexOf("/") === -1) return null;

  let dateParts = input.split("/");

  if (dateParts.length < 3) return null;

  return new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
};

export const stringOrMomentToDate = (input: any): Date => {
  if (typeof input === "string") return new Date(input.split("T")[0]);

  return input.toDate();
};

export const areDatesEqual = (dateOne?: Date, dateTwo?: Date): boolean => {
  if ((dateOne !== null && dateTwo === null) || (dateOne === null && dateTwo !== null)) return false;

  return (
    dateOne.getFullYear() === dateTwo.getFullYear() &&
    dateOne.getMonth() === dateTwo.getMonth() &&
    dateOne.getDate() === dateTwo.getDate()
  );
};

export const filterOutExistingTagVals = (existingTagNames: string[], allTags: FP.Entities.ITag[]): FP.Entities.ITag[] =>
  allTags.filter(t => !existingTagNames.includes(t.text));

export const filterOutExistingProcessAppVals = (
  existingProcessApps: string[],
  allProcessApps: FP.Entities.IProcessApp[]
): FP.Entities.IProcessApp[] => allProcessApps.filter(t => !existingProcessApps.includes(t.name));

export const filterOutExistingProcessesVals = (
  existingProcessApps: string[],
  allProcessApps: FP.Entities.IProcessSummary[]
): FP.Entities.IProcessSummary[] => allProcessApps.filter(t => !existingProcessApps.includes(t.processL3));

export const filterOutExistingUsersVals = (
  existingUserIds: number[],
  allUsers: FP.Entities.IUser[]
): FP.Entities.IUser[] => {
  return allUsers.filter((t: any) => !existingUserIds.includes(t.userId));
};

export const filterOutExistingStRolesVals = (
  existingTagNames: string[],
  stakeholderRoles: FP.Entities.IChangeRole[]
): FP.Entities.IChangeRole[] => stakeholderRoles.filter(t => !existingTagNames.includes(t.name));

export const filterOutExistingIds = (existingIds: number[], allItems: any[]) =>
  allItems.filter(i => !existingIds.includes(i.id));

export const makeCellDeletable = (cellTypes: string | string[]): string[] => [
  ...(cellTypes as string[]),
  "deletableCell"
];

export const makeClearableOnDelete = (res: ColDef, editFn: any) => {
  res.suppressKeyboardEvent = e => {
    const isDeleteKey = e.event.keyCode === 46;
    const isBackspaceKey = e.event.keyCode === 8;
    if (!isDeleteKey && !isBackspaceKey) return false;

    editFn(e.data);
    return true;
  };

  return res.suppressKeyboardEvent;
};

export const openModalOnDelete = (res: ColDef, editFn?: any) => {
  res.suppressKeyboardEvent = e => {
    const isDeleteKey = e.event.keyCode === 46;
    const isBackspaceKey = e.event.keyCode === 8;
    if (!isDeleteKey && !isBackspaceKey) return false;

    editFn(e.data);
    return true;
  };

  return res.suppressKeyboardEvent;
};
